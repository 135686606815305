<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <div class="w3-row" style="margin-top: 0px!important;">
            <div class="w3-card w3-round-large w3-light-gray">
                <div class="w3-row">
                    <div class="w3-bar w3-theme-l1 w3-round tabMenu w3-small">
                        <button class="w3-bar-item w3-button tablink" @click="tab=1" :class="tab==1 ? ' w3-light-grey' : ''">Stock</button>
                        <button class="w3-bar-item w3-button tablink" @click="tab=2" :class="tab==2 ? ' w3-light-grey' : ''">Marketing</button>
                    </div>
                    <div class="w3-row" v-if="tab==1">
                        <div class="w3-row-padding w3-margin-bottom w3-margin-top scrlt-y" v-bind:style="'height:'+  (screenHeight-122) +'px;'">
                            <div class="w3-col s12">
                                <button class="w3-btn w3-block w3-theme-l1 w3-left-align w3-small" style="border-radius: 8px 8px 0 0">FILTER &nbsp;<i class="fa fa-caret-down"></i></button>
                                <div class="w3-animate-zoom w3-white">
                                <form class="w3-container w3-small" id="frmStock" v-on:submit.prevent="showStock" autocomplete="off">
                                    <div class="w3-row">
                                    <div class="w3-col m2 w3-padding">
                                        <label>Period</label>
                                        <input v-model="stock.periode" class="w3-input w3-border-bottom w3-round-large" type="text" placeholder="periode" readonly>
                                    </div>
                                    <div class="w3-col m3 w3-padding">
                                        <label>Location</label>
                                        <select v-model="stock.location" class="w3-select w3-round-large w3-white" style="width: 100%;" required>
                                            <option value="" disabled selected>Pilih Location</option>
                                            <option value="SUB">Surabaya</option>
                                            <option value="SRG">Semarang</option>
                                        </select>
                                    </div>
                                    <div class="w3-col m3 w3-padding">
                                        <label>Warehouse</label>
                                        <model-list-select
                                            :list="gudangs"
                                            class="w3-small"
                                            option-value="LocationID"
                                            option-text="Description"
                                            v-model="stock.gudang"
                                            placeholder="gudang">
                                        </model-list-select>
                                    </div>
                                    <div class="w3-col m4 w3-center" style="padding-top: 34px;">
                                        <button type="submit" class="w3-btn w3-theme w3-round" form="frmStock"><i class="fa fa-search"></i>&nbsp; Show</button>
                                    </div>  
                                    </div>
                                    <br>
                                    <p>
                                    </p>
                                </form>
                                </div>
                                <table class="w3-table w3-bordered w3-striped w3-border w3-small w3-hoverable" id="recstock">
                                    <thead>
                                        <tr class="w3-theme-l3">
                                            <th class="w3-border-right">ID</th>
                                            <th class="w3-border-right">Item</th>
                                            <th class="w3-border-right">qty</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="w3-row" v-if="tab==2">
                        <div class="w3-row-padding w3-margin-bottom w3-margin-top scrlt-y" v-bind:style="'height:'+  (screenHeight-122) +'px;'">
                        <div class="w3-col s12">
                            <button class="w3-small w3-btn w3-block w3-theme-l1 w3-left-align" style="border-radius: 8px 8px 0 0">FILTER &nbsp;<i class="fa fa-caret-down"></i></button>
                            <div class="w3-animate-zoom w3-white">
                            <form class="w3-small w3-container" id="frmMarketing" v-on:submit.prevent="showStockMarketing" autocomplete="off">
                                <div class="w3-row">
                                <div class="w3-col m6 w3-padding">
                                    <label>Period</label>
                                    <input v-model="marketing.periode" class="w3-input w3-border-bottom w3-round-large" type="text" placeholder="periode" readonly>
                                </div>
                                <div class="w3-col m6 w3-center" style="padding-top: 34px;">
                                    <button type="submit" class="w3-btn w3-theme w3-round" form="frmMarketing"><i class="fa fa-search"></i>&nbsp; Show</button>
                                </div>  
                                </div>
                                <br>
                                <p>
                                </p>
                            </form>
                            </div>
                            <table class="w3-table w3-bordered w3-striped w3-border w3-small w3-hoverable" id="recmarketing">
                                <thead>
                                    <tr class="w3-theme-l3">
                                        <th class="w3-border-right">ID</th>
                                        <th class="w3-border-right">Item</th>
                                        <th class="w3-border-right">Gudang</th>
                                        <th class="w3-border-right">Qty</th>
                                        <th class="w3-border-right">SO</th>
                                        <th class="w3-border-right">PO</th>
                                        <th class="w3-border-right">Net Stock</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
// import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

// import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'ItemStock',
    components: {
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            stock: {
                periode : '',
                location : '',
                gudang : '',
                pfunction : 'showstock'
            },
            stocks : [],
            marketing: {
                periode : '',
                pfunction : 'showstockmarketing'
            },
            marketings: [],
            gudangs: [],
            tab : 1
        }
    },
    methods : {
        showStock(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("sybase/ApiSBItemBalance.php",self.stock).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.stocks = response.data.rec;
                $('#recstock').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.stocks,
                    columns: [
                        {"data": "ItemID"},
                        {"data": "name"},
                        {"data": "qty"}
                    ],
                    columnDefs: [
                    ],
                    dom: 'lfBrtip',
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                });
            });
            event.preventDefault();
            return false;
        },
        showStockMarketing(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("sybase/ApiSBItemBalance.php",self.marketing).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.marketings = response.data.rec;
                $('#recmarketing').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.marketings,
                    columns: [
                        {"data": "ItemID"},
                        {"data": "ItemName"},
                        {"data": "GudangName"},
                        {"data": "qtyGudang"},
                        {"data": "soTotal"},
                        {"data": "poTotal"},
                        {"data": "NetStock"}
                    ],
                    columnDefs: [
                    ],
                    dom: 'lfBrtip',
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            event.preventDefault();
            return false;
        },
        setInit() {
            let self = this;
            axios.post("sybase/ApiSBPeriode.php",{
                pfunction : 'getperiode'
            }).then(function (response) {
                self.stock.periode = response.data.rec[0]['PeriodID'];
                self.marketing.periode = response.data.rec[0]['PeriodID'];
            });
            axios.post("sybase/ApiSBGudang.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.gudangs = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
        'tab': function(dt){
            if (dt == 1) {
                $('#recmarketing').DataTable().destroy();
            }else{        
                $('#recstock').DataTable().destroy();
            }
        }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>